<template>
  <section>
    <div class="w-full">
      <span class="p-input-icon-left mb-2">
            <i class="pi pi-search" />
            <InputText v-model="filtros.U_ACS_NmrIdentf.value" @input="listarPacientes()" placeholder="Buscar documento" />
        </span>
      <DataTable showGridlines :value="contratosStore._paginado_pacientes.rows" class="text-sm p-datatable-sm" tableStyle="min-width: 50rem">
        <Column field="U_ACS_NmrIdentf" header="Documento">
          <template #body="slotprops">
            {{ slotprops.data.Paciente.U_ACS_TpoIdentf + ' - ' + slotprops.data.Paciente.U_ACS_NmrIdentf }}
          </template>
          <!-- <template #filter="{ filterModel, filterCallback }">
              <InputText v-model="filterModel.value" type="text" @input="filterCallback(), listarPacientes()" class="p-column-filter" placeholder="" />
          </template> -->
        </Column>
        <Column field="Paciente.U_ACS_NmbPct" header="Nombre"></Column>
        <Column field="Paciente.U_ACS_Regimen" header="Regimen"></Column>
        <Column field="Paciente.U_ACS_Edad" header="Edad"></Column>
        <Column field="Paciente.U_ACS_Ciudad" header="Zonificacion">
          <template #body="slotprops">
            {{ slotprops.data?.Paciente.U_ACS_Ciudad + ', ' + slotprops.data?.Paciente.U_ACS_Dpto }}
          </template>
        </Column>
        <Column field="FecInicio" header="Fecha inicio"></Column>
        <Column field="FecFin" header="Fecha fin"></Column>
        <Column field="" header="Estado">
          <template #body="slotprops">
            <p :class="slotprops.data.Status ? 'text-green-600' : 'text-red-600'" class="font-bold">
              {{ slotprops.data.Status ? 'Activo' : 'Inactivo' }}
            </p>
          </template>
        </Column>
      </DataTable>
      <Paginator :rows="10" @page="onPage($event)" :totalRecords="contratosStore._paginado_pacientes.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
    </div>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { ref, computed, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  export default {
    setup () {
      const contratosStore = useContratosStore()
      const route = useRoute()
      const limit = ref(10)
      const offset = ref(0)
      const filtros = ref({
        U_ACS_NmrIdentf: { value: null }
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const listarPacientes = () => {
        const busqueda = {}
        for (const [key, value] of Object.entries(filtros.value)) {
          if (value.value) {
            busqueda[key] = value.value
          }
        }
        const info = {
          ...params.value,
          ...busqueda
        }
        contratosStore.paginarPacientes(route.params.id, info)
      }
      const onPage = ({ rows, first }) => {
        limit.value = rows
        offset.value = first
        listarPacientes()
      }
      onMounted(() => {
        listarPacientes()
      })
      return {
        contratosStore,
        onPage,
        listarPacientes,
        filtros
      }
    }
  }
</script>
